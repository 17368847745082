import {BSpinner} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const dangerToast = (vm, title, mesage) => {

    vm.$toast({
        component: ToastificationContent,
        props: {
            title: title,
            icon: "AlertTriangleIcon",
            text: mesage,
            variant:"danger",
        },
    })
}
const successToast = (vm, title, mesage) => {

    vm.$toast({
        component: ToastificationContent,
        props: {
            title: title,
            icon: "CheckCircleIcon",
            text: mesage,
            variant:"success",
        },
    })
}
export  {successToast,dangerToast};